<template>
  <div class="esim-compatible">
    <div class="container">
      <div class="row flex-center-between">
        <div class="col-xl-3 col-lg-3 col-md-3 col-xs-6">
          <div class="title">{{ $t('common.compatible_text1') }}</div>
          <div class="subtitle">{{ $t('common.compatible_text2') }}</div>
        </div>
        <div class="col-xl-9 col-lg-9 col-md-9 col-xs-6">
          <van-image fit="contain" :src="require(`../assets/compatible_${$i18n.locale}.png`)"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      
    }
  },
}
</script>
<style lang="scss" scoped>
.esim-compatible{
  margin-top: 40px;
  padding: 20px 0;
  background-color: #f7f4fc;
  .title{
    font-size: 20px;
    font-weight: bold;
  }
  .subtitle{
    font-size: 16px;
    font-weight: bold;
    margin: 5px 0;
  }
}
@media(max-width:768px){
  .esim-compatible{
    margin-top: 20px;
    .title{
      font-size: 18px;
      font-weight: bold;
      text-align: center;
    }
    .subtitle{
      font-size: 14px;
      font-weight: bold;
      text-align: center;
    }
  }
}
</style>