<template>
  <div class="details">
    <a-spin :spinning="isLoading" size="large">
    <div class="container">
      <a-row :gutter="[20,10]">
        <a-col :xs="24" :sm="24" :md="24" :lg="10" :xl="8">
          <img class="product-img" :src="product.cover" alt="">
        </a-col>
        <a-col :xs="24" :sm="24" :md="24" :lg="14" :xl="16">
          <div class="product-name">{{product.title || product.name}}</div>
          <div class="product-price" :class="{'product-price-discount':productInfo.discount.dis||productInfo.discount.dis_spec}">
            <div class="flex-center-start">
              <span>{{productInfo.id ? $t('common.price'):$t('common.from')}}</span>
              <span class="total">{{ currency }} {{productInfo.id ? productInfo.price:lowest_price}}</span> 
              <span class="original-price" v-if="productInfo.ot_price>0&&productInfo.price!==productInfo.ot_price">
                {{ currency }} {{ productInfo.ot_price }}
              </span>
            </div>
            <div class="flex-center-start" v-if="productInfo.discount.dis||productInfo.discount.dis_spec">
              <span>{{$t('common.discount')}}</span>
              <span class="discount tracking-in-expand">
                <a-space :size="10" style="flex-wrap: wrap;">
                  <span class="label" v-if="productInfo.discount.dis">
                    {{productInfo.discount.dis_tips}}
                  </span>
                  <span class="label" v-if="productInfo.discount.dis_spec">
                    {{productInfo.discount.dis_spec_tips}}
                  </span>
                  <span>{{productInfo.discount.dis_mark}}</span>
                </a-space>
              </span>
            </div>
          </div>
          <div v-for="(group,index) in productAttr" :key="index">
            <div class="product-title">{{ group.name }}</div>
            <a-space :size="10" class="sepc-wrappr">
              <div class="space-item-inner" 
                @click="onChangeSku(group.attrValue,item)" 
                v-for="item in group.attrValue" :key="item.attr">
                <button
                  class="default-item"
                  :class="{'active-item':item.check}"
                  :disabled="item.disabled" 
                >
                  {{ item.attr }}
                </button>
                <img 
                  :style="{opacity:item.hasDiscount?1:0}"
                  class="discount-icon" 
                  src="../assets/discount_icon.png" alt=""
                >
              </div>
            </a-space>
          </div>
          <a-button 
            type="buynow" 
            :disabled="!productInfo.id" 
            @click="onBuyNowHandler"
            >
            {{$t('common.buy_now')}}
          </a-button>
          <div class="product-tips">
            <p>{{$t('common.tips_1')}}</p>
            <p>{{$t('common.tips_2')}}</p>
          </div>
        </a-col>
      </a-row>
    </div>
    <esim-compatible/>
    <div class="container">
      <div class="explain">
        <div class="explain-subtitle color-subtitle">{{productDetail.attr_name}}</div>
        <ul class="explain-info">
          <li v-for="(item,index) in productDetail.attr_values" :key="index">
             <span>{{ item.value }}：</span>
             <span>{{ item.detail }}</span>
          </li>
        </ul>
        <div class="explain-subtitle color-subtitle">{{$t('common.title_6')}}</div>
        <div class="html-text" v-html="product.detail"></div>
        <div class="explain-subtitle color-subtitle">{{customer.attr_name}}</div>
        <ul class="explain-info">
          <li v-for="(item,index) in customer.attr_values" :key="index">
             <span>
              <img :src="item.icon" alt="" srcset="">
              {{ item.value }}：
             </span>
             <span>{{ item.detail_val }}</span>
          </li>
        </ul>
        <div class="image-preview"><span  @click="handeDeviceImagePreview">(eSIM {{$t('route.device')}})</span></div>
        <div class="explain-subtitle">{{$t('common.title_5')}}</div>
        <img class="step-img" :src="product.product_detail_img" alt="">
        <div class="image-preview" v-if="$i18n.locale =='zh-tw'">
          {{$t('common.explain')}}：
          <span @click="handeStepImagePreview('zh-hk')">香港版</span>
          <span @click="handeStepImagePreview('zh-tw')">台灣版</span>
        </div>
        <div class="image-preview" v-else><span @click="handeStepImagePreview($i18n.locale)">({{$t('common.explain')}})</span></div>
      </div>
      <div class="section-products">
        <div class="section-title">{{$t('common.other')}}</div>
        <a-row :gutter="[{ xs: 14, sm: 20, xxl: 30},{ xs: 14, sm: 20, xxl: 30}]" class="other-products">
          <a-col :xs="12" :sm="12" :md="8" :lg="6" :xl="6" v-for="item in others" :key="item.district_id">
          <div class="product-item" @click="onReplaceHandler(item)" :title="item.district_name">
            <div class="product-cover">
              <img class="product-img" :src="item.cover" :alt="item.district_name" :title="item.district_name"/>
              <img class="discount-icon" :style="{opacity:item.is_sale?1:0}" src="../assets/discount_icon.png" alt="">
            </div>
            <div class="product-info">
              <div class="product-name text-overflow-example">{{ item.district_name }}</div>
              <div class="product-lowest-price">
                <span class="product-price-from">{{ $t("common.from") }}</span>
                <span>{{ currency }} {{ item.lowest_price }}</span>
              </div>
              <div class="product-icon flex">
                <a-icon type="right" />
              </div>
            </div>
          </div>
          </a-col>
        </a-row>
      </div>
    </div>
    </a-spin>
  </div>
</template>
<script>
import { mapGetters,mapMutations } from 'vuex'
import EsimCompatible from '../components/esim-compatible.vue'
import powerSet from '../utils/power-set.js'
import { ApplicableDeviceImagePreview,ActivateStepImagePreview } from '../utils/imagePreview'
import { ProductInfoAPI,OtherProductAPI } from '../api/api.js'
export default {
  name:'Details',
  components: { 
    EsimCompatible,
  },
  data() {
    return {
      others:[],
      product:{},//商品信息
      productDetail:{},//商品信息
      productAttr:[],//套餐数据
      productValue:[],// 套餐组合数据
      productDiscounts:[],// 折扣信息
      productInfo:{
        id:'',
        price:0,
        discount:{},
        spec:''
      },
      lowest_price:'',
      pathMap:'',
      isLoading:false,
    }
  },
  computed: {
    ...mapGetters("config", ["currency","customer"]),
    ...mapGetters("user", ["isLogin"]),
  },
  mounted(){
    this.initData()
  },
  methods:{
    ...mapMutations(['LOGIN_MODAL_VISIBLE','SET_REDIRECT']),
    // 初始化折扣信息
    filterDiscounts(object){
      return Object.keys(object).filter(key => object[key].type);
    },
    // 初始化规格第一项折扣信息
    initDiscountsState(attr){
      return this.productDiscounts.some(key => key.split('|')[0] === attr);
    },
    async initData(){
      this.isLoading = true
      const { 
        data:{
          product,
          product_detail,
          product_specs:{
            product_attr,
            product_values,
            product_default_value
          }
        } 
      } = await ProductInfoAPI({id:this.$route.query.id})
      this.product = product
      this.productDetail = product_detail
      this.productAttr = product_attr
      this.productValue = product_values
      this.productDiscounts = this.filterDiscounts(product_values) // 初始化折扣信息
      this.pathMap = this.initPathMap(this.productValue)// 初始化路径字典
      this.initDisabledState(this.productAttr)// 初始化禁选状态
      const { data } = await OtherProductAPI({id:this.$route.query.id})
      this.others = data
      const { spec_price } = this.productValue[product_default_value]
      this.lowest_price = spec_price
      this.isLoading = false;
    },
    // 创建生成路径字典对象函数
    initPathMap (goods){
      // 重置下单信息
      // resetProductInfo()
      const pathMap = {}
      // 1. 得到所有有效的Sku集合 
      const effectiveSkus = Object.keys(goods).map((key)=>{
        return { id:goods[key]['id'],specs:key.split("|") }
      })
      // console.log('effectiveSkus',effectiveSkus)
      // 2. 根据有效的Sku集合使用powerSet算法得到所有子集 [1,2] => [[1], [2], [1,2]]
      effectiveSkus.forEach((sku) => {
        // 2.1 获取可选规格值数组
        const selectedValArr = sku.specs
        // 2.2 获取可选值数组的子集
        // console.log('selectedValArr',selectedValArr)
        const valueArrPowerSet = powerSet(selectedValArr)
        // 3. 根据子集生成路径字典对象
        // 3.1 遍历子集 往pathMap中插入数据
        // console.log('valueArrPowerSet',valueArrPowerSet)
        valueArrPowerSet.forEach((arr) => {
          // 根据Arr得到字符串的key，约定使用-分割 ['1GB'，'1Day'] => '1GB-1Day'
          const key = arr.join('|')
          // 给pathMap设置数据
          if (pathMap[key]) {
            pathMap[key].push(sku.id)
          } else {
            pathMap[key] = [sku.id]
          }
        })
      })
      // console.log(pathMap)
      return pathMap
    },
    // 初始化禁选状态 specs：商品源数据 pathMap：路径字典
    initDisabledState(specs){
      // 约定：每一个按钮的状态由自身的disabled进行控制
      specs.forEach((item) => {
        item.attrValue = item.value.map((value)=>{
          // 路径字典中查找是否有数据 有-可以点击 没有-禁用
          return {
            attr:value,
            check:false,
            disabled:!this.pathMap[value],
            hasDiscount:this.initDiscountsState(value) // 初始化折扣信息
          }
        })
      })
    },
    // 选中和取消选中实现
    onChangeSku(group,item){
      // 点击的是未选中，把同一个规格的其他取消选中，当前点击项选中，点击的是已选中，直接取消
      if (item.check) {
        item.check = false
      } else {
        group.forEach((valItem) => valItem.check = false)
        item.check = true
      }
      // 点击按钮时更新禁选状态
      this.updateDisabledState(this.productAttr)
      this.$forceUpdate();
      // console.log(this.getSelectedValues(this.productAttr))
      // 产出SKU对象数据
      const index = this.getSelectedValues(this.productAttr).findIndex(item => item === undefined)
      if (index > -1) {
        // 信息不完整，初始化当前数量、库存、价格
        this.resetProductInfo()
      } else {
        // 信息完整，获取sku对象
        // isLoading.value = false
        const key = this.getSelectedValues(this.productAttr).join('|')
        const { spec_price,ot_price,discount:{dis_tips,dis_spec_tips,dis,dis_spec,dis_mark},id } =this.productValue[key]
        this.productInfo = {
          ...this.productInfo,
          id,
          price:spec_price,
          ot_price,
          spec:key,
          discount:{
            dis_tips, //满减折扣标签
            dis_spec_tips,//促销折扣标签
            dis:Number(dis),//满减折扣价格
            dis_spec:Number(dis_spec),//促销折扣价格
            dis_mark//已减折扣标签
          },
        }
      }
    },
    // 获取选中匹配数组 ['1GB',undefined,undefined]
    getSelectedValues(specs) {
      const arr = []
      specs.forEach((spec) => {
        const selectedVal = spec.attrValue.find((value) => value.check)
        arr.push(selectedVal ? selectedVal.attr : undefined)
      })
      return arr
    },
    // 更新折扣信息
    updateDiscountsState(attr){
      return this.productDiscounts.some(key => key === attr);
    },
    // 更新禁选状态
    updateDisabledState (specs) {
      // 约定：每一个按钮的状态由自身的disabled进行控制
      specs.forEach((item, index) => {
        const selectedValues = this.getSelectedValues(specs)
        item.attrValue.forEach((val) => {
          const _seletedValues = [...selectedValues]
          _seletedValues[index] = val.attr
          const key = _seletedValues.filter(value => value).join('|')
          //规格第一项角标不处理
          if(index) val.hasDiscount = this.updateDiscountsState(key)// 更新折扣信息
          if (val.check) return
          // 路径字典中查找是否有数据 有-可以点击 没有-禁用
          val.disabled = !this.pathMap[key]
        })
      })
    },
    // 重置下单信息
    resetProductInfo(){
      this.productInfo = {
        id:'',
        price:0,
        ot_price:0,
        discount:{},
        spec:''
      }
    },
    onBuyNowHandler(){
      const { cover,name,title } = this.product
      const { price,ot_price,id,spec,discount:{dis,dis_spec} } = this.productInfo
      const redirect =  { 
        name:'Pay',
          query: {
          product_id: this.$route.query.id, // 商品ID
          spec_id:id,// 规格ID
          price,// 价格
          ot_price,// 原价
          dis,// 满减折扣价格
          dis_spec,// 促销折扣价格
          name:title||name,// 商品名称
          spec,// 规格
          cover,// 商品封面
        }
      }
      if(!this.isLogin) {
        this.LOGIN_MODAL_VISIBLE(true)
        this.SET_REDIRECT(redirect)
        return
      }
      this.$router.push(redirect);
    },
    onReplaceHandler({district_id,district_name}){
      if(this.$route.query.id == district_id) return;
      this.$router.replace({name:'Details', query: { id:district_id }});
      window._hmt.push(['_trackEvent', 'button' ,'click', `浏览套餐-${district_name}`]);
    },
    handeDeviceImagePreview(){
      ApplicableDeviceImagePreview()
    },
    handeStepImagePreview(locale){
      if(document.body.clientWidth <= 768){
        this.$router.push({ name: 'Instruction', query: {locale}});
        return
      }
      ActivateStepImagePreview(locale)
    },
  },
}
</script>
<style lang="scss" scoped>
.details{
  font-size: 16px;
  padding-top: 40px;
  .product-img{
    max-width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 6px;
  }
  .product-name{
    font-size: 20px;
    color: #000000;
    font-weight: 600;
  }
  .product-price{
    user-select: none;
    height: 75px;
    color: #fff;
    margin: 20px 0;
    padding: 10px 20px;
    background-color: $color;
    transition: all 0.5s ease;
    .total{
      margin-left: 20px;
      font-size: 34px;
    }
    .original-price{
      margin-left: 10px; 
      text-decoration:line-through;
    }
    .discount{
      line-height: 2;
      margin-left: 20px;
      .label{
        padding: 1px 6px;
        font-size: 12px;
        border-radius: 4px;
        border: 1px solid #FFFFFF;
      }
    }
  }
  .product-price-discount{
    height: 100px;
  }
  .product-title{
    user-select: none;
  }
  .sepc-wrappr{
    padding: 10px 0 20px 0;
    flex-wrap: wrap;
    .space-item-inner{
      position: relative;
      margin-top: 10px;
      .discount-icon{
        pointer-events: none;
        cursor: pointer;
        position: absolute;
        width: 32px;
        height: auto;
        top:-2px;
        right: -2px;
        transition: all 0.5s ease;
      }
    }
    .default-item{
      user-select: none;
      position: relative;
      box-sizing: border-box;
      cursor: pointer;
      padding: 10px 15px;
      min-width: 98px;
      color: #222222;
      font-size: 16px;
      font-weight: bold;
      border: 1px solid #999999;
      border-radius: 6px;
      background-color: #fff;
      transition: all 0.5s ease;
      box-shadow: inset 0 0 0 0 rgba(119, 61, 225, .1);
      &:disabled{
        cursor: not-allowed;
        border-color: #C5C5C5;
        color: rgba(0, 0, 0, 0.25);
        background-color: rgba(0, 0, 0, 0.02);
      }
      // &:not(:disabled):hover {
      //   color: $color;
      //   border-color: $color;
      //   // box-shadow: inset 0 -100px 0 0 rgba(119, 61, 225, .1);
      // }
    }
    .active-item{
      color: $color;
      border-color: $color;
      // box-shadow: $color 0px 7px 29px 0px;
      // background-color: rgba(119, 61, 225, .1);
      box-shadow: inset 0 -100px 0 0 rgba(119, 61, 225, .1);
    }
  }
  .product-tips{
    margin-top: 20px;
  }
  .explain{
    padding: 40px 0;
    border-bottom: 1px solid #F2F3F6;
    .explain-subtitle{
      color: #333333;
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 10px;
    }
    .color-subtitle{
      color: $color;
    }
    .explain-info{
      margin-bottom: 10px;
      li{
        display: flex;
        line-height: 2;
        span{
          &:first-child{
            flex: 1;
            font-weight: 600;
            img{
              width: 16px;
              margin-right: 10px;
            }
          }
          &:last-child{
            flex: 6;
            font-weight: 400;
          }
        }
      }
    }
    .image-preview{
      color: $color;
      font-size: 18px;
      font-weight: 600;
      user-select: none;
      margin-bottom: 10px;
      span{
        cursor: pointer;
        margin-right: 10px;
        text-decoration:underline;
      }
    }
    .step-img{
      object-fit: cover;
    }
    .html-text{
      color: #666666;
      /deep/ p{
        line-height: 2;
        margin-bottom: 0;
        span{
          font-size: 16px !important;
          font-family: "SourceHanSans",sans-serif !important;
        }
      }
    }
  }
}
@media(max-width:992px){
  .details{
    font-size: 16px;
    padding-top: 20px;
    .product-name{
      font-size: 18px;
    }
    .product-price{
      height: 50px;
      margin: 15px 0;
      padding: 10px;
      font-size: 14px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      .total{
        margin-left: 10px;
        font-size: 20px;
      }
      .discount{
        line-height: 2;
        margin-left: 10px;
        .label{
          padding: 1px 6px;
          font-size: 12px;
          border-radius: 4px;
          border: 1px solid #FFFFFF;
        }
      }
    }
    .product-price-discount{
      height: 100px;
    }
    .sepc-wrappr{
      padding: 10px 0 15px 0;
      .default-item{
        cursor: pointer;
        padding: 10px;
        min-width: 80px;
        font-size: 14px;
      }
    }
    .product-tips{
      margin-top: 15px;
      font-size: 14px;
    }
    .explain{
      padding: 20px 0;
      .explain-subtitle{
        font-size: 16px;
      }
      .explain-info{
        li{
          line-height: 1.5;
          span{
            font-size: 14px;
            &:first-child{
              flex: 3;
              font-weight: 600;
            }
            &:last-child{
              flex: 6;
              font-weight: 400;
            }
          }
        }
      }
      .image-preview{
        font-size: 14px;
      }
      .html-text{
        /deep/ p{
          font-size: 14px;
          line-height: 1.5;
          margin-bottom: 0;
          span{
            font-size: 14px !important;
            font-family: "SourceHanSans",sans-serif !important;
          }
        }
      }
    }
  }
}
.tracking-in-expand {
	-webkit-animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
	        animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}

 @-webkit-keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
@keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}


</style>